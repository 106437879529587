import axios from "axios";
import Cookies from "universal-cookie";
import { atom, selector, useRecoilState, useSetRecoilState } from "recoil";
import { authenticated } from "./store";
import { toast } from "react-toastify";
import {
  browserName,
  osName,
  deviceType,
  osVersion,
} from "react-device-detect";

const headers = {
  "Content-Type": "application/json",
  apikey: process.env.REACT_APP_APIKEY,
};

const cookies = new Cookies();
export const headersAuth = {
  "Content-Type": "application/json",
  apikey: process.env.REACT_APP_APIKEY,
  Authorization: "Bearer " + cookies.get("token"),
};

function DoLogin(status) {
  const setAuth = useSetRecoilState(authenticated);
  setAuth((check) => status);
}

export const auth = async (email, password) => {
  axios
    .post(
      process.env.REACT_APP_LOGINURL,
      {
        email: email,
        password: password,
        user_agent:
          browserName + " " + osName + " " + osVersion + " " + deviceType,
      },
      {
        headers: headers,
      }
    )
    .then((response) => {
      cookies.remove("token");
      cookies.remove("name");
      cookies.remove("first");
      cookies.set("token", response.data.OUT_DATA.token);
      cookies.set("name", response.data.OUT_DATA.name);
      window.location = "/home";
    })
    .catch((error) => {
      toast.error("Email/Password Invalid!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
};

export const valid = async () => {
  axios
    .post(
      process.env.REACT_APP_CHECKAUTH,
      {},
      {
        headers: headersAuth,
      }
    )
    .then((response) => {
      return response.data.OUT_STAT;
    })
    .catch((error) => {
      cookies.remove("token");
      cookies.remove("name");
      window.location = "/login";
    });
};
export const firstLog = async (uid) => {
  axios
    .post(
      process.env.REACT_APP_FIRSTLOGIN,
      {
        uuid: uid,
        user_agent:
          browserName + " " + osName + " " + osVersion + " " + deviceType,
      },
      {
        headers: headers,
      }
    )
    .then((response) => {
      return response.data.OUT_STAT;
    })
    .catch((error) => {
      return error.response.data.OUT_STAT;
    });
};

export const Register = async (
  name,
  full_name,
  domisili,
  tgl_lahir,
  email,
  no_handphone,
  password,
  pekerjaan,
  sosmed,
  image
) => {
  const temp = cookies.get("first");
  axios
    .post(
      process.env.REACT_APP_REGISTER,
      {
        uuid: temp,
        name: name,
        full_name: full_name,
        domisili: domisili,
        tgl_lahir: tgl_lahir,
        email: email,
        no_handphone: no_handphone,
        password: password,
        pekerjaan: pekerjaan,
        sosmed: sosmed,
        image: image,
      },
      {
        headers: headers,
      }
    )
    .then((response) => {
      cookies.remove("token");
      cookies.remove("name");
      cookies.remove("first");
      auth(email, password);
    })
    .catch((error) => {
      toast.error("Please check your form!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    });
};

export const UpdateProfile = async (
  name,
  full_name,
  domisili,
  tgl_lahir,
  email,
  no_handphone,
  password,
  pekerjaan,
  sosmed,
  image
) => {
  axios
    .post(
      process.env.REACT_APP_EDITUSER,
      {
        name: name,
        full_name: full_name,
        domisili: domisili,
        tgl_lahir: tgl_lahir,
        email: email,
        no_handphone: no_handphone,
        password: password,
        pekerjaan: pekerjaan,
        sosmed: sosmed,
        image: image,
      },
      {
        headers: headersAuth,
      }
    )
    .then((response) => {
      window.location = "/home";
    })
    .catch((error) => {
      window.location = "/profile";
    });
};

export const authUser = async () => {
  axios
    .post(
      process.env.REACT_APP_AUTHUSER,
      {},
      {
        headers: headersAuth,
      }
    )
    .then((response) => {
      const temp = "abc";
      // console.log(response.data.OUT_DATA[0].name);
      // console.log(temp);
      return temp;
    })
    .catch((error) => {
      if (error.response.data.OUT_STAT === "Z") {
        logout();
      }
      // alert(error.response.data.OUT_STAT);
      console.log(error.response.data.OUT_STAT);
    });
};

export const check = () => {
  const cookies = new Cookies();
  const token = cookies.get("token");
  if (token === undefined || token === null) {
    window.location = "/login";
  }
};

export const logout = () => {
  console.log(cookies.get("token"));
  axios
    .post(
      process.env.REACT_APP_LOGOUT,
      {
        user_agent:
          browserName + " " + osName + " " + osVersion + " " + deviceType,
      },
      {
        headers: headersAuth,
      }
    )
    .then((response) => {
      cookies.remove("token");
      cookies.remove("name");
      window.location = "/";
    })
    .catch((error) => {
      cookies.remove("token");
      cookies.remove("name");
      window.location = "/";
    });
};

export const token = () => {
  const token = cookies.get("token");
  // console.log(token);
  return token;
};

export const uuid = () => {
  const token = cookies.get("first");
  // console.log(token);
  return token;
};

export const name = () => {
  const name = cookies.get("name");
  // console.log(token);
  return name;
};
