import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { token, firstLog } from "../../Api";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";
import axios from "axios";
import {
  browserName,
  osName,
  deviceType,
  osVersion,
} from "react-device-detect";

function First(props) {
  const queryParameters = new URLSearchParams(window.location.search);
  const [tokens, setTokens] = useState(token());
  const location = useLocation();
  const cookies = new Cookies();
  const headers = {
    "Content-Type": "application/json",
    apikey: process.env.REACT_APP_APIKEY,
  };
  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_FIRSTLOGIN,
        {
          uuid: queryParameters.get("uuid"),
          user_agent:
            browserName + " " + osName + " " + osVersion + " " + deviceType,
        },
        {
          headers: headers,
        }
      )
      .then((response) => {
        cookies.set("first", queryParameters.get("uuid"));
        window.location = "/welcoming";
      })
      .catch((error) => {
        window.location = "/login";
      });
  }, []);

  return (
    <>
      <p>Verifying</p>
    </>
  );
}

export default First;
