import { Gallery } from "react-grid-gallery";
import Loading from "../components/Loading";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { headersAuth } from "../Api";
import CardPhoto from "../components/CardPhoto";
import Pagination from "react-bootstrap/Pagination";

const Galleries = () => {
  const [waiting, setWaiting] = useState(false);
  const [albums, setAlbums] = useState([]);
  const [first, setFirst] = useState(true);
  const [loaded, setLoades] = useState(false);
  const [images, setImages] = useState([]);
  const [items, setItems] = useState([]);
  const search = window.location.search.substring(1).replaceAll("%20", " ");

  const changePage = (search, url) => {
    console.log(url);
    setWaiting(false);
    setLoades(false);
    return new Promise((resolve) => {
      axios
        .post(
          url,
          { id: search },
          {
            headers: headersAuth,
          }
        )
        .then((response) => {
          setAlbums(response.data.OUT_DATA.data);
          if (albums.length != 0) {
            let temp = [];
            let active = response.data.OUT_DATA.current_page;
            for (
              let number = 1;
              number <= response.data.OUT_DATA.last_page;
              number++
            ) {
              if (
                number < response.data.OUT_DATA.current_page + 2 &&
                number > response.data.OUT_DATA.current_page - 2
              ) {
                temp.push(
                  <Pagination.Item
                    key={number}
                    active={number === active}
                    onClick={() =>
                      changePage(
                        search,
                        response.data.OUT_DATA.links[number].url
                      )
                    }
                  >
                    {number}
                  </Pagination.Item>
                );
              }
              if (
                number == response.data.OUT_DATA.current_page + 2 ||
                number == response.data.OUT_DATA.current_page - 2
              ) {
                temp.push(
                  <Pagination.Item
                    key={number}
                    active={number === active}
                    onClick={() =>
                      changePage(
                        search,
                        response.data.OUT_DATA.links[number].url
                      )
                    }
                  >
                    ...
                  </Pagination.Item>
                );
              }
              if (number === response.data.OUT_DATA.last_page) {
                temp.push(
                  <Pagination.Item
                    key={number}
                    active={number === active}
                    onClick={() =>
                      changePage(
                        search,
                        response.data.OUT_DATA.links[number].url
                      )
                    }
                  >
                    {number}
                  </Pagination.Item>
                );
              }
            }
            setItems(temp);
            setFirst(false);
            setWaiting(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };

  const getAlbum = () => {
    setWaiting(false);
    return new Promise((resolve) => {
      axios
        .post(
          process.env.REACT_APP_ALBUM,
          { id: search },
          {
            headers: headersAuth,
          }
        )
        .then((response) => {
          setAlbums(response.data.OUT_DATA.data);
          if (albums.length != 0) {
            let temp = [];
            let active = response.data.OUT_DATA.current_page;
            for (
              let number = 1;
              number <= response.data.OUT_DATA.last_page;
              number++
            ) {
              if (
                number < response.data.OUT_DATA.current_page + 2 &&
                number > response.data.OUT_DATA.current_page - 2
              ) {
                temp.push(
                  <Pagination.Item
                    key={number}
                    active={number === active}
                    onClick={() =>
                      changePage(
                        search,
                        response.data.OUT_DATA.links[number].url
                      )
                    }
                  >
                    {number}
                  </Pagination.Item>
                );
              }
              if (
                number == response.data.OUT_DATA.current_page + 2 ||
                number == response.data.OUT_DATA.current_page - 2
              ) {
                temp.push(
                  <Pagination.Item
                    key={number}
                    active={number === active}
                    onClick={() =>
                      changePage(
                        search,
                        response.data.OUT_DATA.links[number].url
                      )
                    }
                  >
                    ...
                  </Pagination.Item>
                );
              }
              if (number === response.data.OUT_DATA.last_page) {
                temp.push(
                  <Pagination.Item
                    key={number}
                    active={number === active}
                    onClick={() =>
                      changePage(
                        search,
                        response.data.OUT_DATA.links[number].url
                      )
                    }
                  >
                    {number}
                  </Pagination.Item>
                );
              }
            }
            setItems(temp);
            setFirst(false);
            setWaiting(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  useEffect(() => {
    if (first) {
      getAlbum();
    }
  });
  return (
    <>
      {waiting ? (
        <>
          <div className="container">
            <div className="row mb-3">
              {albums.map((album) => (
                <CardPhoto
                  img={process.env.REACT_APP_BASEIMGUSERURL + album.path}
                />
              ))}
            </div>
            <div>
              <Pagination>{items}</Pagination>
            </div>
          </div>
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Galleries;
