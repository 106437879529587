import ppdb from ".././assets/ppdb.jpg";
import weekend from ".././assets/weekend.jpg";
import napak_tilas from ".././assets/napak_tilas.jpg";
import oase1 from ".././assets/oase1.jpg";
import oase2 from ".././assets/oase2.jpg";
import { auth } from ".././Api";
import { useState } from "react";
import { MDBCarousel, MDBCarouselItem } from "mdb-react-ui-kit";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  return (
    <>
      <ToastContainer />
      <div style={{ padding: "2% 5% 0 5%", width: "100%" }}>
        <div className="row mb-5 d-flex ">
          <div
            className="col-md-7"
            style={{
              padding: "0",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <MDBCarousel
              showControls
              dealy={3000}
              style={{
                minWidth: "100%",
                flexShrink: "0",
                minHeight: "100%",
                width: "100%",
              }}
            >
              <MDBCarouselItem
                className="w-100 d-block"
                itemId={1}
                src={oase1}
                alt="..."
                style={{
                  minWidth: "100%",
                  flexShrink: "0",
                  minHeight: "100%",
                  width: "100%",
                  height: "100%",
                }}
              />
              <MDBCarouselItem
                className="d-block"
                itemId={2}
                src={oase2}
                alt="..."
                style={{
                  minWidth: "100%",
                  flexShrink: "0",
                  minHeight: "100%",
                  width: "100%",
                }}
              />
              <MDBCarouselItem
                className="d-block"
                itemId={3}
                src={weekend}
                alt="..."
                style={{
                  minWidth: "100%",
                  flexShrink: "0",
                  minHeight: "100%",
                  width: "100%",
                }}
              />
              <MDBCarouselItem
                className="d-block"
                itemId={4}
                src={ppdb}
                alt="..."
                style={{
                  minWidth: "100%",
                  flexShrink: "0",
                  minHeight: "100%",
                  width: "100%",
                }}
              />
              <MDBCarouselItem
                className="d-block"
                itemId={5}
                src={napak_tilas}
                alt="..."
                style={{
                  minWidth: "100%",
                  flexShrink: "0",
                  minHeight: "100%",
                  width: "100%",
                }}
              />
            </MDBCarousel>
          </div>
          <div
            className="col-md-5 d-flex  justify-content-center"
            style={{
              backgroundColor: "#162a12",
              padding: "7.5% 5% 0 5%",
            }}
          >
            <div className="mb-5" style={{ width: "100%" }}>
              <p style={{ color: "white" }}>Selamat Datang</p>
              <p style={{ color: "white" }}>Silahkan Masuk Untuk Melanjutkan</p>
              <input
                type="text"
                placeholder="EMAIL ADDRESS"
                className="form-control rounded-pill"
                onInput={(e) => setEmail(e.target.value)}
                style={{
                  width: "50%",
                  minWidth: "250px",
                  display: "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                  backgroundColor: "#D9D9D9",
                  textAlign: "center",
                }}
              ></input>
              <input
                type="password"
                placeholder="PASSWORD"
                className="form-control rounded-pill mt-2"
                onInput={(e) => setPassword(e.target.value)}
                style={{
                  width: "50%",
                  minWidth: "250px",
                  display: "block",
                  marginRight: "auto",
                  marginLeft: "auto",
                  backgroundColor: "#D9D9D9",
                  textAlign: "center",
                }}
              ></input>
              <button
                onClick={() => auth(email, password)}
                className="btn btn-primary rounded-pill mb-2 mt-2"
                name="login"
                style={{
                  width: "50%",
                  minWidth: "250px",
                  backgroundColor: "#ecf39c",
                  color: "#000",
                  fontFamily: "Ubuntu",
                  fontWeight: "bold",
                }}
              >
                LOG IN
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
