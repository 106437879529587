import comingsoon from "../assets/logo_undercons.png";
const Comingsoon = (props) => {
  return (
    <div className="container mt-5">
      <img src={comingsoon} alt="..." style={{ width: "25%" }}></img>
      <h1 style={{ fontWeight: "bolder", fontSize: "3rem" }}>Sabar ya...</h1>
      <h3 style={{ fontWeight: "bolder", fontSize: "4rem" }}>
        Masih coming soon{" "}
      </h3>
      <h5 style={{ textAlign: "justify" }}>*{props.text}</h5>
    </div>
  );
};

export default Comingsoon;
