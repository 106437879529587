import Button from "react-bootstrap/Button";
import Form from "../components/Form";
import { useState, useEffect } from "react";
import logo from "../assets/logoAngkatan.png";
import aksos from ".././assets/AKSOS.jpg";

const FirstLogin = () => {
  const [showResults, setShowResults] = useState(0);
  const [max, setMaxs] = useState(3);
  const [min, setMins] = useState(0);

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <>
      <div className="container mt-5">
        <div
          className="row"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="col-md-1"
            alt="logoAng"
            src={logo}
            style={{
              width: "100%",
              maxWidth: "75px",
              maxHeight: "75px",
            }}
          ></img>
          {windowSize.width >= 784 ? (
            <div className="col-md-2" style={{ padding: "1% 0 0 0" }}>
              <p
                style={{
                  fontSize: "1.75rem",
                  fontWeight: "bold",
                  width: "100%",
                  marginRight: "auto",
                  textAlign: "start",
                }}
              >
                Veintisiete
              </p>
            </div>
          ) : (
            <div className="mb-2"></div>
          )}
        </div>
      </div>

      {showResults === 0 ? (
        <div
          className="col"
          style={{
            position: "relative",
            marginLeft: "auto",
            marginRight: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            src={aksos}
            alt="aksos"
            style={{
              width: "100%",
              opacity: "0.75",
            }}
          ></img>

          {windowSize.width >= 784 ? (
            <div
              className="col-md-12"
              style={{
                position: "absolute",
                top: "10%",
                marginRight: "auto",
                marginLeft: "auto",
              }}
            >
              <h3 style={{ fontWeight: "400" }}>Selamat datang di website</h3>
              <h1
                style={{
                  fontSize: "8em",
                  color: "#ecf39c",
                  textShadow: "2px 2px 5px #000000",
                }}
              >
                VEINTISIETE
              </h1>
              <Button
                className="mb-1 mt-5 shadow-lg"
                name="login"
                style={{
                  width: "100%",
                  maxWidth: "150px",
                  backgroundColor: "#ecf39c",
                  color: "#162a12",
                  fontFamily: "Gotham",
                  fontWeight: "900",
                  marginRight: "auto",
                  marginLeft: "auto",
                  border: "0",
                  borderRadius: "25px",
                }}
                onClick={() => {
                  setShowResults(showResults + 1);
                }}
              >
                Next
              </Button>
            </div>
          ) : (
            <div
              className="col"
              style={{
                position: "absolute",
                top: "10%",
                marginRight: "auto",
                marginLeft: "auto",
                width: "100%",
              }}
            >
              <h3 style={{ fontWeight: "400" }}>Selamat datang di website</h3>
              <h1
                style={{
                  fontSize: "5em",
                  color: "#ecf39c",
                  textShadow: "2px 2px 5px #000000",
                }}
              >
                VEINTISIETE
              </h1>
              <Button
                className="mb-1 mt-5 shadow-lg"
                name="login"
                style={{
                  width: "100%",
                  maxWidth: "150px",
                  backgroundColor: "#ecf39c",
                  color: "#162a12",
                  fontFamily: "Gotham",
                  fontWeight: "900",
                  marginRight: "auto",
                  marginLeft: "auto",
                  border: "0",
                  borderRadius: "25px",
                }}
                onClick={() => {
                  setShowResults(showResults + 1);
                }}
              >
                Next
              </Button>
            </div>
          )}
        </div>
      ) : (
        <div>
          {showResults === 1 ? (
            <div className="container mb-5">
              <div style={{ padding: " 0 20% 0 20%" }}>
                <h2 style={{ fontWeight: "bold", fontSize: "3rem" }}>
                  HALO TEMEN-TEMEN
                </h2>
                <div
                  className="container mt-4"
                  style={{ textAlign: "justify", fontWeight: "500" }}
                >
                  <p>
                    Gimana kabar kalian? Aku gak bisa tanyain kalian satu-satu,
                    tapi kayanya bakal seru kalo kita semua ngumpul lagi entah
                    kapan nanti untuk tukeran cerita satu sama lain. Bentar lagi
                    ada yang udah lulus kuliah, mungkin juga udah ada yang punya
                    pekerjaan, intinya apapun yang kalian lakukan semoga
                    semuanya lancar. Aku mungkin gak bisa bantu banyak untuk
                    perjalanan hidup kalian kedepannya dan menurutku juga lebih
                    baik untuk kita berjuang masing-masing. Pasti bakal lebih
                    banyak pelajaran yang didapet dan kita juga bisa berkembang
                    lebih cepat lagi.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div>
              {showResults === 2 ? (
                <div className="container mb-5">
                  <div style={{ padding: " 0 20% 0 20%" }}>
                    <h2 style={{ fontWeight: "bold", fontSize: "3rem" }}>
                      HALO TEMEN-TEMEN
                    </h2>
                    <div
                      className="container row mt-4"
                      style={{ textAlign: "justify", fontWeight: "500" }}
                    >
                      <div className="col-md-6">
                        <p>
                          Ini aku buatin web untuk kita semua dan bisa dipakai
                          bareng untuk 3 tahun kedepan. Nanti kalian bisa
                          manfaatin untuk apapun yang bisa dukung kalian. Yang
                          ada dipikiranku, web ini bisa dipakai untuk kalian
                          yang punya usaha dalam bentuk apapun untuk taruh
                          produk atau jasa kalian secara online. Kontak aku aja
                          kalo kalian tertarik untuk lebih lanjut. Gak ada tarif
                          apapun kok, santai aja kalau ke aku. Tapi aku gak bisa
                          janji cepet ya, nanti kita bisa diskusi lebih lanjut
                          lagi.
                          <br></br>Kalian juga bisa nulis-nulis cerita kalian di
                          sini, bisa kalian pakai untuk sekedar cerita, promosi
                          karya yang kalian punya, dsb. Aku usahain akhir bulan
                          ini udah jadi ya...
                          <br></br>Terus, web ini bisa dipakai jadi pusat
                          informasi, jadi aku harap semuanya memperbaharui data
                          diri kalian tiap tahun. Paling gak tiap 17 juli. Siapa
                          tahu ada yang butuh jasa dari kalian kan?
                          <br></br>Sama satu lagi, kalau kalian mau bikin cv,
                          web ini bisa dipakai. Jadi nanti cv kalian bisa
                          diakses online.
                        </p>
                      </div>
                      <div className="col-md-6">
                        <p>
                          Sementara aku baru bikinin untuk taruh foto-foto kita
                          di bagian album, nanti kalian bisa cek dan liat. Kalau
                          semisal kalian masih nyimpen foto-foto kita entah
                          apapun itu bisa banget untuk kontak aku, nanti aku
                          upload biar semua bisa akses dan gak pakai gdrive
                          kalian lagi. <br></br>O ya, aku juga terbuka kalau
                          kalian mau bikin fitur apapun untuk web ini. Entah
                          kalian cuma ngasih ide mentah atau kalian juga mau
                          ikut buat dan bantu ngembangin webnya terutama yang
                          minat dan belajar di UI/UX. Stack yang aku pakai
                          laravel untuk backend, react untuk frontend.
                          <br></br> Dan semisal kalian yang gak bisa programming
                          tapi mau belajar, aku juga terbuka untuk kita belajar
                          bareng. Mungkin bisa dibikin rutin asal kalian memang
                          niat. Aku cuma minta feedback untuk bantu ngerawat web
                          ini aja.
                        </p>
                        <p style={{ textAlign: "end" }}>Pacil</p>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="container">
                  <Form />
                </div>
              )}
            </div>
          )}
        </div>
      )}
      <div
        className="row"
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div className="col-md-2">
          {showResults !== min && showResults !== max ? (
            <Button
              className="mb-1"
              name="login"
              style={{
                width: "100%",
                backgroundColor: "#162a12",
                color: "#ecf39c",
                fontFamily: "Ubuntu",
                fontWeight: "bold",
                marginRight: "auto",
                marginLeft: "auto",
                border: "0",
                borderRadius: "25px",
              }}
              onClick={() => {
                setShowResults(showResults - 1);
              }}
            >
              Back
            </Button>
          ) : (
            <></>
          )}
        </div>
        <div className="col-md-2">
          {showResults !== max && showResults !== min ? (
            <Button
              className="mb-1"
              name="login"
              style={{
                width: "100%",
                backgroundColor: "#ecf39c",
                color: "#162a12",
                fontFamily: "Ubuntu",
                fontWeight: "bold",
                marginRight: "auto",
                marginLeft: "auto",
                border: "0",
                borderRadius: "25px",
              }}
              onClick={() => {
                setShowResults(showResults + 1);
              }}
            >
              Next
            </Button>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default FirstLogin;
