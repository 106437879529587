import "./App.css";
import Navigationbar from "./components/partials/Navbar/Navbar";
import Footer from "./components/partials/Footer/Footer";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import Test from "./pages/Test";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Contact from "./pages/Contact";
import Story from "./pages/Story";
import Comingsoon from "./pages/Comingsoon";
import Profile from "./pages/Profile";
import Authenticated from "./components/middleware/Authenticated";
import Guest from "./components/middleware/Guest";
import First from "./components/middleware/First";
import NoPass from "./components/middleware/NoPass";
import { Fragment } from "react";
import FirstLogin from "./pages/FirstLogin";
import Store from "./pages/Store";
import bg from "./assets/BoW.png";
import ScrollButton from "./components/ScrollButton";
import Album from "./pages/Album";
import Galleries from "./pages/Gallery";
import "./firebase-messaging-sw";

const styles = {
  header: {
    backgroundImage: `url(${bg})`,
    height: "100vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  },

  content: {
    height: "100%",
    width: "100%",
    backgroundColor: "rgba(0.2, 0.2, 0.2, 0.2)",
  },
};
const path = window.location.pathname !== "/welcoming";

function App() {
  return (
    <div className="App">
      <div>
        <Navigationbar />
      </div>
      <div className="body">
        <div style={{ overflow: "hidden", position: "relative" }}>
          <img
            style={{
              opacity: "0.05",
              position: "absolute",
              left: "0",
              top: "0",
              width: "100%",
              height: "auto",
            }}
            src={bg}
            alt=""
          />
          <div style={{ position: "relative" }}>
            <Router>
              <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route
                  path="/home"
                  element={
                    <Authenticated>
                      <Home />
                    </Authenticated>
                  }
                ></Route>
                <Route
                  path="/story"
                  element={
                    <Comingsoon text="Nanti kalian bisa tulis cerita kalian sendiri, bebas, gak harus tentang sekolah. Pakai aja untuk tempat kalian cerita karena nanti tulisan di sini bisa dibaca untuk umum." />
                    // <Story />
                  }
                ></Route>
                <Route
                  path="/store"
                  element={
                    <Comingsoon text="Kalian yang punya usaha apapun, bisa pakai web ini untuk promosi. Mau jualan, usaha, dsb." />
                    // <Store />
                  }
                ></Route>
                <Route
                  path="/profile"
                  element={
                    <Authenticated>
                      <Profile />
                    </Authenticated>
                  }
                ></Route>
                <Route
                  path="/album"
                  element={
                    <Authenticated>
                      <Album />
                    </Authenticated>
                  }
                ></Route>
                <Route
                  path="/contact"
                  element={
                    <Authenticated>
                      <Contact />
                    </Authenticated>
                  }
                ></Route>
                <Route
                  path="/gallery"
                  element={
                    <Authenticated>
                      <Galleries />
                    </Authenticated>
                  }
                ></Route>
                <Route
                  path="/login"
                  element={
                    <Guest>
                      <Login />
                    </Guest>
                  }
                ></Route>
                <Route
                  path="/signup"
                  element={
                    <Guest>
                      <Register />
                    </Guest>
                  }
                ></Route>
                <Route
                  path="/welcome"
                  element={
                    <First>
                      <FirstLogin />
                    </First>
                  }
                ></Route>
                <Route
                  path="/welcoming"
                  element={
                    <NoPass>
                      <FirstLogin />
                    </NoPass>
                  }
                ></Route>
              </Routes>
            </Router>
          </div>
        </div>
      </div>
      {path ? <ScrollButton /> : <></>}

      <div className="foot mt-2">
        <Footer />
      </div>
    </div>
  );
}

export default App;
