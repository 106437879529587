import Card from "react-bootstrap/Card";
import blank from "../assets/blankUserImage.jpg";
import { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import Cookies from "universal-cookie";

const Form = () => {
  const cookies = new Cookies();

  const [namalengkap, setNamaLengkap] = useState("Nama Lengkap");
  const [panggilan, setPanggilan] = useState("");
  const [photo, setPhotos] = useState("");
  const [domisili, setDomisili] = useState("");
  const [email, setEmail] = useState("Email");
  const [tgl_lahir, setTgl_Lahir] = useState("");
  const [noHp, setNoHp] = useState("");
  const [profesi, setProfesi] = useState("");
  const [password, setPassword] = useState("");
  const [instagram, setInstagram] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const onSelectFile = async (e) => {
    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined);
      return;
    }

    setSelectedFile(e.target.files[0]);
    var file = e.target.files[0];
    const b64 = await getBase64(file);
    setPhotos(b64);
  };
  const Register = (
    name,
    full_name,
    domisili,
    tgl_lahir,
    email,
    no_handphone,
    password,
    pekerjaan,
    sosmed,
    image
  ) => {
    const temp = cookies.get("first");
    axios
      .post(
        process.env.REACT_APP_REGISTER,
        {
          uuid: temp,
          name: name,
          full_name: full_name,
          domisili: domisili,
          tgl_lahir: tgl_lahir,
          email: email,
          no_handphone: no_handphone,
          password: password,
          pekerjaan: pekerjaan,
          sosmed: sosmed,
          image: image,
        },
        {
          headers: {
            "Content-Type": "application/json",
            apikey: process.env.REACT_APP_APIKEY,
          },
        }
      )
      .then((response) => {
        cookies.remove("token");
        cookies.remove("name");
        cookies.remove("first");
        toast.success("Register Success!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        window.location = "/login";
      })
      .catch((error) => {
        toast.error("Please check your form!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  return (
    <>
      <ToastContainer />
      <div>
        <Card className="mb-3" style={{ backgroundColor: "transparent" }}>
          <Card.Header
            style={{ backgroundColor: "transparant", color: "whitesmoke" }}
          >
            Data Diri
          </Card.Header>
          <Card.Body style={{ backgroundColor: "transparent" }}>
            <Card.Title>Lengkapin dulu dong, yang bener ya...</Card.Title>
            <div className="row mt-4">
              <div className="col-md-4 border-end border-secondary d-flex align-items-center justify-content-center">
                <div className="d-flex flex-column align-items-center text-center p-3 py-5 align-me">
                  <span>
                    <div>
                      {selectedFile && (
                        <img
                          src={preview}
                          style={{ clipPath: "circle()", width: "300px" }}
                          alt="..."
                        />
                      )}
                      {!selectedFile && (
                        <img
                          src={blank}
                          style={{
                            clipPath: "circle()",
                            width: "60%",
                            minWidth: "200px",
                          }}
                          alt="..."
                        />
                      )}
                      <input
                        className="mt-2"
                        type="file"
                        onChange={onSelectFile}
                        style={{ marginLeft: "15%" }}
                      />
                    </div>
                  </span>
                  {namalengkap ? (
                    <span className="font-weight-bold mt-2">{namalengkap}</span>
                  ) : (
                    <span className="font-weight-bold mt-2">Nama Lengkap</span>
                  )}
                  {email ? (
                    <span className="text-black-50">{email}</span>
                  ) : (
                    <span className="font-weight-bold mt-2">Email</span>
                  )}
                  <span></span>
                </div>
              </div>
              <div className="col-md-8">
                <div style={{ width: "100%" }}>
                  <p style={{ textAlign: "left" }}>Nama Lengkap</p>
                  <input
                    type="text"
                    onInput={(e) => setNamaLengkap(e.target.value)}
                    placeholder="Nama Lengkap"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                  <p style={{ textAlign: "left" }}>Panggilan</p>
                  <input
                    type="text"
                    onInput={(e) => setPanggilan(e.target.value)}
                    placeholder="Panggilan"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                  <p style={{ textAlign: "left" }}>Domisili</p>
                  <input
                    type="text"
                    onInput={(e) => setDomisili(e.target.value)}
                    placeholder="Domisili Saat Ini"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                  <p style={{ textAlign: "left" }}>Email</p>
                  <input
                    type="text"
                    onInput={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                  <p style={{ textAlign: "left" }}>Password</p>
                  <input
                    type="password"
                    onInput={(e) => setPassword(e.target.value)}
                    placeholder="(minimal 8 karakter, huruf besar, huruf kecil, angka, simbol)"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                  <p style={{ textAlign: "left" }}>Tanggal Lahir</p>
                  <input
                    type="date"
                    onInput={(e) => setTgl_Lahir(e.target.value)}
                    placeholder="Email"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                  <p style={{ textAlign: "left" }}>Instagram</p>
                  <input
                    type="text"
                    onInput={(e) => setInstagram(e.target.value)}
                    placeholder="Instagram"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                  <p style={{ textAlign: "left" }}>No Handphone</p>
                  <input
                    type="number"
                    onInput={(e) => setNoHp(e.target.value)}
                    placeholder="No Handphone"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                  <p style={{ textAlign: "left" }}>
                    Profesi (Mahasiswa / kosongin juga gpp)
                  </p>
                  <input
                    type="text"
                    onInput={(e) => setProfesi(e.target.value)}
                    placeholder="Profesi Saat Ini"
                    className="form-control mb-3"
                    style={{
                      width: "100%",
                      minWidth: "250px",
                      display: "block",
                      marginRight: "auto",
                      marginLeft: "auto",
                      backgroundColor: "#D9D9D9",
                      borderRadius: "5px",
                    }}
                  ></input>
                </div>
              </div>
            </div>
            {namalengkap !== "" &&
            panggilan !== "" &&
            domisili !== "" &&
            email !== "" &&
            tgl_lahir !== "" &&
            password !== "" &&
            instagram !== "" ? (
              <button
                className="rounded-pill mb-1"
                name="login"
                onClick={() => {
                  Register(
                    panggilan,
                    namalengkap,
                    domisili,
                    tgl_lahir,
                    email,
                    noHp,
                    password,
                    profesi,
                    instagram,
                    photo
                  );
                }}
                style={{
                  width: "5%",
                  minWidth: "75px",
                  minHeight: "40px",
                  backgroundColor: "#44BA80",
                  color: "#0C1738",
                  fontFamily: "Ubuntu",
                  fontWeight: "bold",
                  right: "0",
                  marginLeft: "85%",
                  border: "0",
                }}
              >
                Save
              </button>
            ) : (
              <></>
            )}
          </Card.Body>
        </Card>
      </div>
    </>
  );
};
export default Form;
