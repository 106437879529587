import styled from "styled-components";

export const Heading = styled.h1`
  text-align: center;
  color: green;
`;

export const Content = styled.div`
  overflowy: scroll;
  height: 2500px;
  margin-right: 1%;
  margin-left: auto;
  position: sticky;
`;

export const Button = styled.div`
  position: -webkit-sticky;
  position: sticky;
  margin-right: 1%;
  margin-left: auto;
  bottom: 50px;
  height: 20px;
  font-size: 3rem;
  z-index: 1;
  cursor: pointer;
  color: black;
`;
