import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Redirect } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CardAlbum = (props) => {
  const gallery = (title) => {
    window.location = "/gallery?" + title;
  };
  return (
    <>
      <div
        className="mt-3"
        style={{ marginLeft: "auto", marginRight: "auto", width: "18rem" }}
      >
        <Card
          style={{ maxHeight: "400px" }}
          onClick={() => {
            gallery(props.title);
          }}
        >
          <Card.Img
            variant="top"
            src={props.img}
            style={{
              minHeight: "192px",
              maxHeight: "192px",
              objectFit: "cover",
              opacity: "0.9",
            }}
          />
          <Card.Body
            style={{
              width: "100%",
              textAlign: "start",
            }}
          >
            <Card.Title
              style={{
                fontWeight: "bold",
                textTransform: "uppercase",
                fontSize: "1rem",
              }}
            >
              {props.title}
            </Card.Title>
            <a href={props.link} download>
              <Button
                onClick={() => {
                  toast.info("Processing", {
                    position: toast.POSITION.TOP_RIGHT,
                  });
                }}
                variant="primary"
                style={{ justifyItems: "left", left: "0" }}
              >
                Download
              </Button>
            </a>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default CardAlbum;
