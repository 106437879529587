import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import { Redirect } from "react-router-dom";
const CardAlbum = (props) => {
  const gallery = (title) => {
    window.location.replace(
      process.env.REACT_APP_SELFURL + "/gallery?" + title
    );
  };
  const download = (e) => {
    e.preventDefault();
    fetch(e.target.href, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        response.arrayBuffer().then(function (buffer) {
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          console.log(url);
          link.setAttribute("download", "image.JPG"); //or any other extension
          document.body.appendChild(link);
          link.click();
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      className="col-md-3 mt-3"
      style={{ marginLeft: "auto", marginRight: "auto", width: "20rem" }}
    >
      <Card
        onClick={() => {
          // gallery(props.title);
        }}
      >
        {/* <a href={props.img} download onClick={(e) => download(e)}> */}
        <Card.Img
          variant="top"
          src={props.img}
          style={{
            minHeight: "192px",
            maxHeight: "192px",
            objectFit: "cover",
          }}
        />
        {/* </a> */}
      </Card>
    </div>
  );
};

export default CardAlbum;
