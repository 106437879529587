import aksos from ".././assets/AKSOS.jpg";
import CarrouseslStory from "../components/CarousselStory";
import CardCaroussel from "../components/CardCaroussel";
import ScrollButton from "../components/ScrollButton";

const LandingPage = () => {
  return (
    <>
      <div className="mb-5" style={{ padding: "0 5%" }}>
        <img
          src={aksos}
          alt="aksos"
          style={{ width: "100%", overflow: "hidden", borderRadius: "25px" }}
        ></img>
      </div>
      <p style={{ fontWeight: "bold", fontSize: "2rem", color: "black" }}>
        Sejarah
      </p>
      <hr className="mb-1" style={{ width: "10%", margin: "auto" }}></hr>
      <div
        className="container"
        style={{ width: "90%", maxWidth: "614px", textAlign: "justify" }}
      >
        <p className="mb-5 mt-3" style={{ fontWeight: "500", color: "black" }}>
          Cerita kami dimulai pada 17 Juli 2017 di kota kecil bernama Muntilan.
          200 siswa-siswi Sekolah Menengah Atas dari berbagai latar belakang ini
          dikumpulkan untuk hidup bersama di asrama selama tiga tahun. Waktu
          yang tidak sebentar untuk saling berbagi suka dan duka. Sampai-sampai,
          kami menganggap satu sama lain seperti keluarga.
        </p>
      </div>

      {/* <div
        className="mb-3"
        style={{
          padding: "5%",
          width: "100%",
          borderRadius: "25px",
        }}
      >
        <CarrouseslStory style={{ objectFit: "contain" }} />
      </div>
      <div>
        <h2
          style={{
            color: "black",
            fontWeight: "bold",
            fontSize: "2.5rem",
            lineHeight: "normal",
          }}
        >
          <span>juga ada terdapat</span>
          <br></br>
          <span style={{ backgroundColor: "#ecf39c" }}>bisnis kecil </span>
          masing-masing
          <br></br>
          <span>dari kami</span>
        </h2>
      </div>
      <div style={{ width: "100%" }}>
        <CardCaroussel />
      </div> */}
    </>
  );
};

export default LandingPage;
