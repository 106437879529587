import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyBUfKMycJwzN_4fQUJtT9tGIEncHM9unDo",
  authDomain: "web-veintisiete.firebaseapp.com",
  projectId: "web-veintisiete",
  storageBucket: "web-veintisiete.appspot.com",
  messagingSenderId: "279972347426",
  appId: "1:279972347426:web:908a41ffd02d01a0c0547b",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Cloud Messaging and get a reference to the service

function requestPermission() {
  Notification.requestPermission().then((permission) => {
    if (permission === "granted") {
      const messaging = getMessaging(app);
      getToken(messaging, {
        vapidKey:
          "BFNKEzTWvUJjkb8_-dTXyjslCEXRDu9SjwAtw8_FpM2doT5-lnbk23iutpX15c5rzV_5fMTxuHJjKq9qOn_Axsw",
      }).then((currentToken) => {});
    }
  });
}

requestPermission();
