import React, { useEffect, useState } from "react";
import axios from "axios";
import { headersAuth } from "../Api";
import Loading from "../components/Loading";
import Table from "react-bootstrap/Table";
import Pagination from "react-bootstrap/Pagination";

const Contact = () => {
  const [waiting, setWaiting] = useState(false);
  const [users, setUser] = useState();
  const [loaded, setLoades] = useState(false);
  const [first, setFirst] = useState(false);
  const [items, setItems] = useState([]);

  const changePage = (search, url) => {
    console.log(url);
    setWaiting(false);
    setLoades(false);
    return new Promise((resolve) => {
      axios
        .post(
          url,
          { search: search },
          {
            headers: headersAuth,
          }
        )
        .then((response) => {
          setUser(response.data.OUT_DATA.data);
          let temp = [];
          let active = response.data.OUT_DATA.current_page;
          for (
            let number = 1;
            number <= response.data.OUT_DATA.last_page;
            number++
          ) {
            temp.push(
              <Pagination.Item
                key={number}
                active={number === active}
                onClick={() =>
                  changePage(search, response.data.OUT_DATA.links[number].url)
                }
              >
                {number}
              </Pagination.Item>
            );
          }
          setItems(temp);
          setWaiting(true);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  const getUser = (search) => {
    setWaiting(false);
    setLoades(false);
    return new Promise((resolve) => {
      axios
        .post(
          process.env.REACT_APP_CONTACT,
          { search: search },
          {
            headers: headersAuth,
          }
        )
        .then((response) => {
          setUser(response.data.OUT_DATA.data);
          let temp = [];
          let active = response.data.OUT_DATA.current_page;
          for (
            let number = 1;
            number <= response.data.OUT_DATA.last_page;
            number++
          ) {
            temp.push(
              <Pagination.Item
                key={number}
                active={number === active}
                onClick={() =>
                  changePage(search, response.data.OUT_DATA.links[number].url)
                }
              >
                {number}
              </Pagination.Item>
            );
          }
          setItems(temp);
          setWaiting(true);
          setFirst(true);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  useEffect(() => {
    if (waiting) {
      setLoades(true);
    } else {
      if (!first) {
        const temp = getUser();
      }
    }
  }, [waiting]);

  return (
    <>
      {first ? (
        <div className="mt-3 mb-3">
          <input
            type="text"
            placeholder="Cari kontak temen - temen di sini (Nama, panggilan, domisili)"
            className="form-control rounded-pill"
            onInput={(e) => getUser(e.target.value)}
            style={{
              width: "50%",
              minWidth: "250px",
              display: "block",
              marginRight: "auto",
              marginLeft: "auto",
              textAlign: "center",
              borderWidth: "thick",
            }}
          ></input>
        </div>
      ) : (
        <></>
      )}
      {loaded ? (
        <>
          {users.length != 0 ? (
            <>
              <div className="container">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>Nama Lengkap</th>
                      <th>Panggilan</th>
                      <th>Email</th>
                      <th>Domisili</th>
                      <th>Pekerjaan</th>
                      <th>No Handphone</th>
                      <th>Sosmed</th>
                    </tr>
                  </thead>
                  <tbody>
                    {users.map((user) => (
                      <tr key={user.id}>
                        <td> {user.full_name} </td>
                        <td> {user.name}</td>
                        <td> {user.email}</td>
                        <td> {user.domisili}</td>
                        <td> {user.pekerjaan}</td>
                        <td> {user.no_telp}</td>
                        <td> {user.sosmed}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div>
                  <Pagination>{items}</Pagination>
                </div>
              </div>
            </>
          ) : (
            <div className="container">
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Empty</th>
                  </tr>
                </thead>
              </Table>
            </div>
          )}
        </>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Contact;
