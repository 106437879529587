import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authenticated } from "../../store";
import { token, valid } from "../../Api";
import { useNavigate, Navigate, useLocation } from "react-router-dom";

function Authenticated(props) {
  const auth = useRecoilValue(authenticated);
  const [tokens, setTokens] = useState(token());
  const [valids, setValids] = useState(valid());
  const location = useLocation();

  if (tokens === undefined) {
    return <Navigate to="/login" state={{ from: location }} />;
  } else {
    if (valids === "Z") {
      return <Navigate to="/login" state={{ from: location }} />;
    } else {
      return props.children;
    }
  }
}

export default Authenticated;
