import React, { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { authenticated } from "../../store";
import { uuid, valid } from "../../Api";
import { useNavigate, Navigate, useLocation } from "react-router-dom";
import Cookies from "universal-cookie";

function NoPass(props) {
  const [uuids, setUuids] = useState(uuid());
  const location = useLocation();
  const cookies = new Cookies();

  if (uuids === undefined) {
    return <Navigate to="/login" state={{ from: location }} />;
  }

  return props.children;
}

export default NoPass;
