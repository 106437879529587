import lapangan from "../assets/forHome.jpg";
import { name, headersAuth } from "../Api";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Loading from "../components/Loading";
import "./Home.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Home = () => {
  const [names, setNames] = useState(name());
  const queryParameters = new URLSearchParams(window.location.search);
  const [waiting, setWaiting] = useState(false);
  const [Quo, setQuo] = useState("");
  const [addQuo, setaddQuo] = useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  const getQotd = () => {
    return new Promise((resolve) => {
      axios
        .post(
          process.env.REACT_APP_QOTD,
          {},
          {
            headers: headersAuth,
          }
        )
        .then((response) => {
          setQuo(response.data.OUT_DATA);
          setWaiting(true);
        })
        .catch((error) => {
          // alert(error.response.data.OUT_STAT);
          console.log(error);
        });
    });
  };
  const sendQotd = (content, name) => {
    console.log(content);
    console.log(name);
    axios
      .post(
        process.env.REACT_APP_CREATEQOTD,
        {
          content: content,
          author: name,
        },
        {
          headers: headersAuth,
        }
      )
      .then((response) => {
        toast.success("Create QOTD Success!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose();
      })
      .catch((error) => {
        toast.error("Create QOTD Failed!", {
          position: toast.POSITION.TOP_RIGHT,
        });
        handleClose();
      });
  };

  useEffect(() => {
    getQotd();
  }, []);
  return (
    <>
      <ToastContainer />
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Kamu mau tulis apa?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            placeholder=""
            className="form-control rounded-pill"
            onInput={(e) => setaddQuo(e.target.value)}
            style={{
              width: "75%",
              minWidth: "250px",
              display: "block",
              marginRight: "auto",
              marginLeft: "auto",
              textAlign: "center",
              borderWidth: "thick",
            }}
          ></input>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={() => sendQotd(addQuo, names)}>
            Kirim
          </Button>
        </Modal.Footer>
      </Modal>
      {waiting ? (
        <div className="mt-3 container">
          <div className=" row container">
            {windowSize.width >= 1127 ? (
              <>
                <div
                  className="col-md-7"
                  style={{ backgroundColor: "#fbfbfb" }}
                >
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <h3
                        style={{
                          textAlign: "left",
                          fontWeight: "400",
                          lineHeight: "1px",
                        }}
                      >
                        Halo,
                      </h3>
                      <h1
                        style={{
                          textAlign: "left",
                          fontWeight: "bolder",
                          textTransform: "uppercase",
                          fontSize: "6rem",
                        }}
                      >
                        {names}!
                      </h1>
                      <div style={{ textAlign: "end" }}>
                        <Button onClick={handleShow}>Add QOTD</Button>
                      </div>
                      <br></br>
                      <div
                        className="quo"
                        style={{
                          textAlign: "right",
                          marginLeft: "auto",
                          marginRight: "0",
                          fontFamily: "Gotham",
                          fontStyle: "italic",
                          fontWeight: "300",
                          float: "right",
                        }}
                      >
                        <h3>{Quo}</h3>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <img
                    src={lapangan}
                    alt="Image Home"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                      minWidth: "526px",
                    }}
                  ></img>
                </div>
              </>
            ) : (
              <>
                <div
                  className="col-md-12"
                  style={{ backgroundColor: "#fbfbfb" }}
                >
                  <div className="row mt-5">
                    <div className="col-md-12">
                      <h3
                        style={{
                          textAlign: "left",
                          fontWeight: "400",
                          lineHeight: "1px",
                        }}
                      >
                        Halo,
                      </h3>
                      <h1
                        style={{
                          textAlign: "left",
                          fontWeight: "bolder",
                          textTransform: "uppercase",
                          fontSize: "6rem",
                        }}
                      >
                        {names}!
                      </h1>
                      <div style={{ textAlign: "end" }}>
                        <Button onClick={handleShow}>Add QOTD</Button>
                      </div>
                      <br></br>
                      <div
                        className="quo"
                        style={{
                          textAlign: "right",
                          marginLeft: "auto",
                          marginRight: "0",
                          fontFamily: "Gotham",
                          fontStyle: "italic",
                          fontWeight: "300",
                          float: "right",
                        }}
                      >
                        <h3>{Quo}</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      ) : (
        <>
          <Loading />
          <p>loading</p>
        </>
      )}
    </>
  );
};

export default Home;
