import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Button from "react-bootstrap/Button";
import { token, logout, name } from "../../../Api";
import React, { useState, useEffect } from "react";
import { FaCaretDown } from "react-icons/fa";
import logo from "../../../assets/logoAngkatan.png";
import "./Navbar.css";

function BasicExample() {
  const location = window.location;
  const [tokens, setTokens] = useState(token());
  const [names, setNames] = useState(name());
  const [selectedMenu, setSelectedMenu] = useState("LAINNYA");
  const path = window.location.pathname === "/login";
  const path2 = window.location.pathname === "/welcoming";
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    if (typeof window !== "undefined") {
      function handleResize() {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      if (
        window.location.pathname == "/story" ||
        window.location.pathname == "/store" ||
        window.location.pathname == "/home" ||
        window.location.pathname == "/"
      ) {
        setSelectedMenu("LAINNYA");
      } else {
        setSelectedMenu(window.location.pathname.substring(1));
      }
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return (
    <div>
      {!path2 ? (
        <Navbar id="nav" expand="lg" style={{ padding: "10px 0" }}>
          <Container>
            <Navbar.Brand
              href="/"
              style={{
                width: "13%",
                minWidth: "150px",
              }}
            >
              <div className="row">
                <img
                  className="col-md-2"
                  alt="logoAng"
                  src={logo}
                  style={{ width: "10%", minWidth: "70px", maxHeight: "50px" }}
                ></img>
                <div className="col-md-2" style={{ padding: "15px 0 0 0" }}>
                  {windowSize.width >= 771 ? (
                    <p style={{ fontWeight: "bold" }}>Veintisiete</p>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse
              id="basic-navbar-nav"
              style={{
                marginLeft: "25%",
              }}
            >
              {tokens ? (
                <Nav
                  className="ml-auto "
                  style={{
                    alignSelf: "center",
                    marginRight: "auto",
                  }}
                  activeKey={location.pathname}
                >
                  <Nav.Link href="/home">HOME</Nav.Link>
                  <Nav.Link href="/story">STORY</Nav.Link>
                  <Nav.Link href="/store">DAGANGAN</Nav.Link>
                  <NavDropdown
                    title={selectedMenu}
                    id="basic-nav-dropdown"
                    style={{
                      textTransform: "uppercase",
                      fontWeight: selectedMenu != "LAINNYA" ? "800" : "",
                      fontFamily: "Gotham",
                      borderBottomWidth:
                        selectedMenu != "LAINNYA" ? "3px" : "0px",
                      borderBottomStyle: "solid",
                    }}
                  >
                    <NavDropdown.Item href="/album">
                      Album Kita
                    </NavDropdown.Item>
                    <NavDropdown.Item href="/contact">Kontak</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item>Coming Soon</NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              ) : (
                <div>
                  {/* {!path2 ? ( */}
                  <Nav
                    className="ml-auto"
                    style={{
                      alignSelf: "center",
                      justifyContent: "center",
                      marginRight: "auto",
                      minWidth: "290px",
                    }}
                    activeKey={location.pathname}
                  >
                    <Nav.Link href="/story">STORY</Nav.Link>
                    <Nav.Link href="/store">DAGANGAN</Nav.Link>
                  </Nav>
                  {/* ) : (
                <></>
              )} */}
                </div>
              )}

              {tokens ? (
                <Nav style={{ marginLeft: "auto" }}>
                  <NavDropdown
                    id="pro"
                    title={
                      <Button
                        style={{
                          // backgroundColor: "black",
                          background: "transparent",
                          borderColor: "black",
                          color: "black",
                          borderRadius: "25px",
                          borderWidth: "3px",
                          width: "100px",
                          fontWeight: "bold",
                          textTransform: "uppercase",
                        }}
                      >
                        {names}
                        <FaCaretDown />
                      </Button>
                    }
                  >
                    <NavDropdown.Item href="/profile">Profile</NavDropdown.Item>
                    <NavDropdown.Divider />
                    <NavDropdown.Item onClick={() => logout()}>
                      Logout
                    </NavDropdown.Item>
                  </NavDropdown>
                </Nav>
              ) : (
                <>
                  {path || path2 ? (
                    <></>
                  ) : (
                    <Nav style={{ marginLeft: "auto" }}>
                      <Button
                        className="shadow "
                        href="/login"
                        style={{
                          backgroundColor: "#ecf39c",
                          color: "#0C1738",
                          border: "0",
                          borderRadius: "25px",
                          minWidth: "100px",
                          // padding: "10px",
                          // boxShadow: "5px 10px #888888",
                        }}
                      >
                        <b>LOG IN</b>
                      </Button>
                    </Nav>
                  )}
                </>
              )}
            </Navbar.Collapse>
          </Container>
        </Navbar>
      ) : (
        <></>
      )}
    </div>
  );
}

export default BasicExample;
