import CardAlbum from "../components/CardAlbum";
import Loading from "../components/Loading";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { headersAuth } from "../Api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const Album = () => {
  const [waiting, setWaiting] = useState(false);
  const [albums, setAlbums] = useState([]);
  const getAlbum = () => {
    let id = 0;
    return new Promise((resolve) => {
      axios
        .post(
          process.env.REACT_APP_ALBUM,
          { id: id },
          {
            headers: headersAuth,
          }
        )
        .then((response) => {
          setAlbums(response.data.OUT_DATA);
          setWaiting(true);
        })
        .catch((error) => {
          console.log(error);
        });
    });
  };
  useEffect(() => {
    getAlbum();
  }, []);
  return (
    <>
      {waiting ? (
        <div>
          <div className="mt-3">
            <h3>Dokumentasi Kenangan Yang Pernah Ada</h3>
          </div>
          <div className="container">
            <div className="row">
              {albums.map((album) => (
                <div className="col">
                  <CardAlbum
                    title={album.name}
                    img={process.env.REACT_APP_BASEIMGUSERURL + album.image}
                    link={process.env.REACT_APP_BASEIMGUSERURL + album.path}
                    content="penjelasan singkat"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <>
          <Loading />
        </>
      )}
      <ToastContainer />
    </>
  );
};

export default Album;
